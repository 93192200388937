// ! Only add scss variables here, DO NOT include any css rendered styles!!!!!!!!!!!!!

.widget {
    box-sizing: border-box;
    padding:10px;
    font-family:Arial, sans-serif;
    font-size:1em;
    display:inline-block;
}

sub {
    display:block;
    color:rgb(177, 177, 177);
}

/** TODO: Remove this class once you have figured out the sizing of your widget */
.testing-layout-hint {
    // border:rgb(209, 85, 105) solid 4px;
    // color:rgb(206, 110, 126);
    // background:rgb(245, 235, 237);
}

// Safari has special padding it applies to lists that no one else does >:(
ul, ol {
    padding-inline-start: 0;
    margin-block-start: 0;
    margin-block-end: 0;
}
