// ! Only add scss variables here, DO NOT include any css rendered styles!!!!!!!!!!!!!

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
    font-family: 'Norwester';
    src: url('@/resources/fonts/norwester.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

$border-width: 4px;
$color-coral: #ce5143;

.global-header-container{
    position: relative;
    z-index: 10;
    width: 100%;
    height: 100%;
    background: white;
    font-family: 'Norwester', sans-serif;
    // font-family: "vista-slab", serif;
    box-sizing:border-box;

}

.global-header-container .global-header{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: $border-width solid #C4B197;
    background:white;
    z-index:3;
}

.global-header-container .global-header .logo{
        align-self: center;
        display: inline-flex;
        gap: 10px;
        width: v-bind(navbarWidth);
        cursor: pointer;
        z-index:3;

        .icon{
            padding-left: 30px;
            width: 75px;
            //padding-left: 50px;
            //width: 50px;
        }

        .title{
            width: 130px;
            
        }

        .icon, .title{
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }

        .icon svg{
            height: 40px;
        }
}

.global-header-container .global-header .navigation{
    display: flex;
    justify-content: space-between;
    width: calc(100% - v-bind(navbarWidth));
    z-index:3;

    &.full-width{
        width: 100%;
        justify-content: flex-end;
    }
}
.global-header-container .global-header .navigation.mobile{
    flex-grow: 0 !important;
}

.global-header-container .global-header .navigation .tabs{
    $skew-angle: 20deg;
    display:flex;
    gap:10px;
    //display: grid;
    //grid-template-columns: repeat(5, 1fr);
    align-items: center;
    //background:red;
    //justify-content: center;
    justify-content: space-between;
    font-size: 20px;
    text-align: center;
    //transform: skewX(-$skew-angle);

    .section{
        height: 100%;
        position: relative;

        .tab{
            position: relative;
            padding: 0 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            vertical-align: baseline;
            cursor: pointer;
            clip-path: polygon(15% 0%, 100% 0%, 85% 100%, 0% 100%);


            .text{
                //transform: skewX($skew-angle);
                white-space: nowrap;

            }
        }

        

        .nav-dropdown{
            position: absolute;
            // top: calc(v-bind(headerHeight) - $border-width);
            top: 100%;
            left: -25%;
            width: 150%;
            // padding: 1rem;
            background: rgba(255, 255, 255, 1);
            border: $border-width solid #C4B197;
        }

        &.selected .tab{
            color: white;
            background: #CD5144;
        }
    }
}

.global-header-container .section {
    .section-links{
        .section-header{
            padding: 0.5rem 1rem;
        }
    }
    .primary-link, .section-link{
        padding: 1rem;
    }
}

.global-header-container .section,
.global-header-container .dropdowns{
    .primary-links{
        font-size: 20px;
        // font-weight: bold;
        font-family: 'Norwester';
    }

    .section-links{
        font-size: 15px;
        margin: 1rem 0;

        .section-header{
            font-size: 20px;
            color: $color-coral;
            cursor: default;
        }

        .section-link{
            font-family: "vista-slab", serif;
        }

    }

    .primary-links, .section-links {
        display: flex;
        flex-direction: column;
        text-align: left;
        gap: 5px;

        .primary-link, .section-link{

            &:hover{
                background: #CD5144;
                color: white;
                cursor: pointer;
            }
        }
    }

    .section-links{
        gap: 5px;
    }

    a{
        color: black;
        text-decoration: none;
        // margin-top: 20px;
    }
}

        
.global-header-container .global-header .search-and-actions{
    transition: all .25s ease-in-out;
    display:flex;
    gap: 40px;

    &.expanded{
        flex-grow:1;
        margin-left:30px;

        .search-wrapper,.shrunk-search-wrapper{
            width:100%;
        }

        
       
    }
}

.global-header-container .global-header .search-and-actions .search-wrapper{
    height: 70%;
    display:flex;
    align-items: center;
    align-self: center;
    width: 250px;
    border:2px solid #E1DFD4;
    border-radius: 37px;
    background-color: #F5F6F9;
    padding-left:10px;
    box-sizing: border-box;
    position:relative;

    .search{
        font-family: "Poppins", serif;
        border:none;
        height: 100%;
        width:100%;
        box-sizing: border-box;
        outline:none;
        padding-left:5px;
        background-color: #F5F6F9;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 37px;
        position:relative;
        font-size: 18px;
    }


    .search::placeholder{
        color: #CACACA;
    }

    .search-btn{
        height:100%;
        background: #C4B197;
        color:white;
        font-family: 'norwester', sans-serif;
        border:none;
        border-left: 2px solid #C4B197;
    }

    .results{
        z-index:5;
        position:absolute;
        top:105%;
        left:-2px;
        width:calc(100% + 4px);
        //width: 100%;
        background-color: #F5F6F9;
        border:2px solid #E1DFD4;
        display:flex;
        flex-direction: column;
        align-items: flex-start;
        font-family: "Poppins", sans-serif;
        font-weight:light;

        .result{
            box-sizing: border-box;
            padding-top:5px;
            padding-bottom:5px;
            padding-left: 10px;
            cursor: pointer;
        }

        .view-all-wrapper{
            width:100%;
            border-radius: 0px 0px 15px 15px;
        }

        .view-all{
            margin-top:5px;
            width:100%;
            border:none;
            border-radius: 0px 0px 15px 15px;
            display:flex;
            padding: 10px;
            color: #3A583B;
            font-family: "Poppins", sans-serif;
            font-weight:light;
            cursor: pointer;
        }

        .loader{
            padding: 10px;
            font-size: 16px;
            color: #C4B197;
        }

        .dots{
            display:inline-block;
            width: 1.5em;
            text-align: left;
            animation: dots 1.5s steps(4, end) infinite;
        }
    }

    &.has-results{
        border-radius: 15px 15px 0px 0px;
        .search{
            border-radius: 15px 15px 0px 0px;
        }
        .results{
            border-radius: 0px 0px 15px 15px;
        }
    }
}

.global-header-container .global-header .search-and-actions .shrunk-search-wrapper{
    display:flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    box-sizing: border-box;
    position:relative;
    height: 70%;
    width:30px;
    
    .search-icon{
        display:flex;
        align-items: center;
        justify-content: center;
        width:100%;
        height:100%;
        background-color: #F5F6F9;
        border:2px solid #E1DFD4;
        border-radius: 37px;
    }

    .shrunk-search-container{
        display:flex;
        align-items: center;
        width:100%;
        height:100%;
        padding-left:10px;
        background-color: #F5F6F9;
        border:2px solid #E1DFD4;
        border-radius: 37px;

        .search{
            font-family: "Poppins", serif;
            border:none;
            height: 100%;
            width:100%;
            box-sizing: border-box;
            outline:none;
            padding-left:5px;
            background-color: #F5F6F9;
            padding: 10px;
            box-sizing: border-box;
            border-radius: 37px;
            position:relative;
            font-size: 18px;
        }

    }
    
    .results{
        z-index:5;
        position:absolute;
        //top:105%;
        top: 95%;
        //width:calc(100% - 4px);
        width:100%;
        background-color: #F5F6F9;
        border:2px solid #E1DFD4;
        display:flex;
        flex-direction: column;
        align-items: flex-start;
        font-family: "Poppins", sans-serif;
        font-weight:light;

        .result{
            padding-top:5px;
            padding-bottom:5px;
            padding-left: 10px;
            cursor: pointer;
        }

        .view-all-wrapper{
            width:100%;
            border-radius: 0px 0px 15px 15px;
        }

        .view-all{
            margin-top:5px;
            width:100%;
            border:none;
            border-radius: 0px 0px 15px 15px;
            display:flex;
            padding: 10px;
            color: #3A583B;
            /* stylelint-disable-next-line order/properties-order */
            font-family: "Poppins", sans-serif;
            font-weight:light;
            cursor: pointer;
        }

        .loader{
            padding: 10px;
            font-size: 16px;
            color: #C4B197;
        }

        .dots{
            display:inline-block;
            width: 1.5em;
            text-align: left;
            animation: dots 1.5s steps(4, end) infinite;
        }
    }

    &.has-results{
        .shrunk-search-container{
            border-radius: 15px 15px 0px 0px;
        }
        //.search{
        //    border-radius: 15px 15px 0px 0px;
        //}
        //.results{
        //    border-radius: 0px 0px 15px 15px;
        //}
    }
    
}




.global-header-container .global-header .search-and-actions .actions{
    display: flex;
    gap: 10px;
    align-items: center;
    margin-right: 30px;
    z-index: 15;

    .instagram, .facebook{
        display:flex;
        align-items: center;
        height:100%;
        cursor: pointer;
    }

    .search-btn{
        $side-padding: 20px;
        //max-height: 22px;
        max-height:70%;
        color: $color-coral;
        display:flex;
        align-items: center;
        background: #E1DFD4;
        padding: 5px $side-padding;
        font-family: "Norwester", sans-serif;
        font-weight:Bold;
        border-radius: 5px;
        font-size: 15px;
        border:none;
        cursor: pointer;
    }

    .account{
        $side-padding: 5px;
        max-height: 22px;
        display: flex;
        gap: 10px;
        align-items: center;
        color: $color-coral;
        background: #E1DFD4;
        padding: 2px $side-padding;
        border-radius: 5px;
        font-size: 15px;
        position: relative;
        cursor: pointer;

        .main-action, .action{
            padding: 2px $side-padding;
            font-family: 'norwester', sans-serif;
            font-weight:bold;

        }

        .action{
            color: black;

        }

        .chevy{
            display: flex;
            align-items: center;
            transform: rotate(180deg);
        }

        &.open{
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            .chevy{
                transform: unset;
            }
        }

        .account-dropdown{
            position: absolute;
            top: 100%;
            left: 0;
            width: calc(100% - 2 * $side-padding);
            display: flex;
            gap: 10px;
            align-items: center;
            color: $color-coral;
            background: #E1DFD4;
            padding: 2px $side-padding;
            border-radius: 5px;

            border-top-left-radius: 0;
            border-top-right-radius: 0;
            font-size: 15px;
        }
    }

    .close-btn-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        height:100%;
        z-index:2;
        cursor: pointer;
    }
}

.global-header-container .dropdowns{
    position: fixed;
    top: calc(v-bind(headerHeight) - $border-width);
    left:0;
    z-index: 100;
    width: 100%;
    box-sizing: border-box;
    background: white;
    border: $border-width solid #C4B197;

    .dropdown-base{
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-top: 20px;
        padding-left: 30px;
        margin:unset;
        margin-bottom: 20px;
    }


    .section-dropdown{
        font-size: 20px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        padding: 20px;

        .chevy{
            transform: rotate(180deg);
            svg path { fill: black;}
        }

        &.selected{
            background: #8A4D4D;
            color: white;

            .chevy{
                transform: unset;
                svg path { fill: white;}
            }
        }
    }

    &.showDropDowns{
        opacity: 1;
        transform: translateY(0);
    }

    // a{
    //     color: black;
    //     text-decoration: none;
    //     // margin-top: 20px;
    // }
}

@media only screen and (max-width: 1835px){
    .global-header-container .global-header{
        justify-content: space-between;
        gap: 60px;
    }
    
    .global-header-container .global-header .navigation{
        width:unset;
        flex-grow: 1;
        gap:60px;
        //justify-content: space-evbenly;
    }

    .global-header-container .global-header .logo{
        width:unset;

        .icon{
            padding-left: 20px;
        }
    }
} 

@media only screen and (max-width: 1638px) {
    .global-header-container .global-header{
        justify-content: space-between;
        gap: 30px;
    }

    .global-header-container .global-header .navigation{
        gap: 30px;

        .tab{
            padding: 0 20px !important;

            .text{
                font-size: 18px;
            }
        }
    }

    .global-header-container .global-header .navigation .nav-dropdown{
        .primary-links{
            font-size: 18px;
        }
    }

    .global-header-container .global-header .search-and-actions{
        gap: 15px;
        
        .search-wrapper{
            width:120px;

            .search{
                font-size: 18px;
            }
        }
    }
}

@media only screen and (max-width: 1280px) {
    .global-header-container .global-header{
        gap: 10px;
    }

    .global-header-container .global-header .navigation{
        gap: 10px;

        .tab{
            padding: 0 15px !important;
        }

        .tab .text{
            font-size: 16px;
        }
    }

    .global-header-container .global-header .navigation .nav-dropdown{
        .primary-links{
            font-size: 16px;
        }
    }

    .global-header-container .global-header .search-and-actions{
        .search-wrapper .search{
            font-size: 16px;
        }
    }
}


.fade-slide-enter-active, .fade-slide-leave-active {
  transition: transform .25s ease, opacity .25s ease; 
}

.fade-slide-enter-from {
  opacity: 0;
  transform: translateX(-100%);
}

.fade-slide-enter-to {
  opacity: 1;
  transform: translateX(0);
}

.fade-slide-leave-from {
  opacity: 1;
  transform: translateX(0); 
}

.fade-slide-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}





.fade-slide-far-enter-active, .fade-slide-far-leave-active {
  transition: transform .25s ease, opacity .25s ease; 
}

.fade-slide-far-enter-from {
  opacity: 0;
  transform: translateX(-300%);
}

.fade-slide-far-enter-to {
  opacity: 1;
  transform: translateX(0);
}

.fade-slide-far-leave-from {
  opacity: 1;
  transform: translateX(0); 
}

.fade-slide-far-leave-to {
  opacity: 0;
  transform: translateX(-300%);
}

@keyframes dots {
  0% {
    content: '.';
  }
  25% {
    content: '..';
  }
  50% {
    content: '...';
  }
  75% {
    content: '';
  }
}
